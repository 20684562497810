import React, { useState, useReducer, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, Checkbox, MenuItem, FormControl, FormGroup, RadioGroup, Radio, FormControlLabel} from '@mui/material';

import HelpToolTip from '../../../components/HelpToolTip';

import threatAssessmentService from '../../../services/threatAssessment';
import AppContext from '../../../context/app'
import AdditionalInterviewer from './AdditionalInterviewer';

export default function Interview(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId, role, threatAssessmentInterviewId } = useParams();

  const [loading, setLoading] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const [interview, setInterview] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { sections: [] }
  );
  const [assessment, setAssessment] = useState(null)
  const [assessmentModel, setAssessmentModel] = useState(null)

  useEffect( async () => {
    try {
      let editMode = !!threatAssessmentInterviewId
      setEditMode(editMode)

      let threatAssessment, loadedInterview
      //load interviewer data
      if (threatAssessmentInterviewId ) {
        loadedInterview = await new threatAssessmentService().getThreatAssessmentInterview(threatAssessmentInterviewId)
        threatAssessment = await new threatAssessmentService().get(loadedInterview.threatAssessmentId)
      }
      else
        threatAssessment = await new threatAssessmentService().get(threatAssessmentId)

      let loadedAssessmentModel = await new threatAssessmentService().getAssessmentModelByAssessmentId(threatAssessment.threatAssessmentId)
      if (!threatAssessmentInterviewId )
        loadedInterview = loadedAssessmentModel.interviewTypes.find(it => it.role === role)  


      setAssessmentModel(loadedAssessmentModel)
      setAssessment(threatAssessment)

      let interviewQuestions = await new threatAssessmentService().getQuestions(threatAssessment.institutionBuildingId, loadedInterview.questionType)

      interviewQuestions.forEach( (interviewQuestion) => {
        //hide those that should be piped
        if (interviewQuestion.config?.pipe)
          interviewQuestion.hide = true;
      
        if (!editMode) {
          // auto-default answers
          if (interviewQuestion.config?.autoAnswerSameAsQuestionId ) {
            threatAssessment.interviewers.forEach( (otherInterview) => {
              otherInterview.questionSections.forEach( (otherInterviewQuestionSections) => {
                let otherInterviewQuestion = otherInterviewQuestionSections.questions.find( (question) => { return question.threatAssessmentInterviewQuestionId === interviewQuestion.config.autoAnswerSameAsQuestionId } )
                if (otherInterviewQuestion && otherInterviewQuestion.answers) {
                  let otherInterviewAnswers = otherInterviewQuestion.answers
                  //get the choice with the same text
                  let sameChoices = interviewQuestion.answerChoices.filter( (choice) => { return otherInterviewAnswers.some( a => a.text === choice.text) || otherInterviewAnswers.some( a => a.isYes === choice.isYes) } ) 
                  //set the answer
                  if (otherInterviewQuestion.comment)
                    interviewQuestion.comment = `${interviewQuestion.comment || ''}${otherInterviewQuestion.comment}\n`
                  interviewQuestion.previouslyAnswered = true;
                  if (sameChoices.length) {
                    sameChoices.forEach( (sameChoice) => {
                      interviewQuestion.answer = [{ threatAssessmentInterviewQuestionId: sameChoice.threatAssessmentInterviewQuestionId, threatAssessmentInterviewQuestionAnswerChoiceId: sameChoice.threatAssessmentInterviewQuestionAnswerChoiceId  }]
                    })
                  }
                }
              })
            })
          }
        }
        //edit mode set answers
        else{
          let loadedInterviewQuestions = loadedInterview.questionSections.flatMap( y => y.questions )
          let loadedInterviewQuestion = loadedInterviewQuestions.find( q => q.threatAssessmentInterviewQuestionId === interviewQuestion.threatAssessmentInterviewQuestionId )
          interviewQuestion.answer = loadedInterviewQuestion.answers
          interviewQuestion.comments = loadedInterviewQuestion.comments
        }
      })


      if (loadedInterview.config.intervieweeName === 'studentOfConcern') {
        loadedInterview.intervieweeFirstName = threatAssessment.assessedFirstName 
        loadedInterview.intervieweeLastName = threatAssessment.assessedLastName 
      }
      
      let interviewQuestionSections = [...new Set(interviewQuestions.map( (interviewQuestion) => { return interviewQuestion.section }))].map( (iq) => { return { section: iq ? iq : null } })
      interviewQuestionSections.forEach( (interviewQuestionSection) => {
        interviewQuestionSection.questions = interviewQuestions.filter( (interviewQuestion) => { return interviewQuestion.section === interviewQuestionSection.section })
        interviewQuestionSection.sectionSubText = interviewQuestionSection.questions[0].sectionSubText
        interviewQuestionSection.sectionConfig = interviewQuestionSection.questions[0].sectionConfig
      })
      //default objects
      interviewQuestionSections.forEach(section => {
        section.questions.forEach( question => {
          question.answer = question.answer ?? []
          question.comments = question.comments ?? {}
        })
      })
      loadedInterview.sections = interviewQuestionSections
      setInterview(loadedInterview)
      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  const handleAnswerChange = (event, interviewQuestion) => {
    let answer = interviewQuestion.answerChoices.find( (answer) => { return answer.threatAssessmentInterviewQuestionAnswerChoiceId === +event.target.value} )
    
    //get questionId and answer already answered
    if (event.target.type === 'radio') 
      if (interviewQuestion.answer.length)
        //maintain the answer id for editign   
        interviewQuestion.answer = interviewQuestion.answer.map( interviewAnswer => { return { threatAssessmentInterviewAnswersId: interviewAnswer.threatAssessmentInterviewAnswersId, ...answer } })
      else
        interviewQuestion.answer = [answer]
    else if (event.target.type === 'checkbox'){
      //add to answers
      if (event.target.checked){
        interviewQuestion.answer = interviewQuestion.answer.map( interviewAnswer => { return { threatAssessmentInterviewAnswersId: interviewAnswer.threatAssessmentInterviewAnswersId, ...interviewAnswer } })
        interviewQuestion.answer.push(answer)
      }
      //remove
      else
        interviewQuestion.answer = interviewQuestion.answer.filter(item => item.threatAssessmentInterviewQuestionAnswerChoiceId !== answer.threatAssessmentInterviewQuestionAnswerChoiceId )
    }

   

    //check for piped question to unhide them
    interview.sections.forEach( (section) => {
      section.questions.forEach( (question) => {
        if(question.config?.pipe?.includes(interviewQuestion.threatAssessmentInterviewQuestionId))
          question.hide = !answer.isYes;
      }) 
    })
    

    //auto answer other questions related to this question
    interview.sections.forEach( (section) => {
      section.questions.forEach( (question) => {
        if (question.config?.autoAnswerChoice?.answerChoiceId === answer.threatAssessmentInterviewQuestionAnswerChoiceId){
          //auto answer
          //if not already answered
          if (!question.answer || !question.answer.length) {
            let sameChoice = question.answerChoices.find(ac => ac.threatAssessmentInterviewQuestionAnswerChoiceId === question.config?.autoAnswerChoice?.answerWithChoiceId)
            if (sameChoice) {
              question.answer = [sameChoice]
              question.autoAnswered = true
            }
          }  
        }        
      })
    })

    setInterview(interview)
  }

  const handleTriggerAdditionalAssessmentNameChange = (event, interviewQuestion) => {
    if (!interviewQuestion.triggerAdditionalAssessment)
      interviewQuestion.triggerAdditionalAssessment = { }

    interviewQuestion.triggerAdditionalAssessment[event.target.name] = event.target.value
    setInterview(interview)
  }

  
  //save the interview
  const handleSave = async (event) => {
    event.preventDefault();

     //flatten the sections to get the questions and answers
     let threatAssessmentInterviewQuestions = interview.sections.flatMap( y => y.questions )
     threatAssessmentInterviewQuestions = threatAssessmentInterviewQuestions.filter(q => q.answer.length || !q.hide )
     let threatAssessmentInterviewAnswers = threatAssessmentInterviewQuestions.map( (q) => { return { threatAssessmentInterviewQuestionId: q.threatAssessmentInterviewQuestionId, answer: q.answer, comments: q.comments, triggerAdditionalAssessment: q.triggerAdditionalAssessment } })
     try {
      let interviewData = { 
        threatAssessmentId: +threatAssessmentId,
        threatAssessmentInterviewId: +threatAssessmentInterviewId, 
        threatAssessmentInterviewAnswers,  
        role: interview.role,
        methodOfInterview: interview.methodOfInterview,
        intervieweeFirstName: interview.intervieweeFirstName,
        intervieweeLastName: interview.intervieweeLastName,
        grade: interview.grade,
        age: interview.age,
        gender: interview.gender,
        intervieweeID: interview.intervieweeID,
        intervieweeType: interview.intervieweeType,
        isCurrentIntervieweeType: interview.isCurrentIntervieweeType,
        school: interview.school,
        building: interview.building,
        emergencyContactName: interview.emergencyContactName,
        emergencyContactRelationship: interview.emergencyContactRelationship,
        intervieweeHomeAddress: interview.intervieweeHomeAddress,
        intervieweePhone: interview.intervieweePhone,
        methodOfInterviewLocation: interview.methodOfInterviewLocation, 
        data: interview.data
      }
      if(editMode)
        await new threatAssessmentService().updateInterview(interviewData)
      else
        await new threatAssessmentService().saveInterview(interviewData)
      props.history.push({pathname: `/assessment/detail/${assessment.threatAssessmentId}` });
      context.toastSuccess('Interview saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Interview saved')
      Sentry.captureException(err);
    }
  }


  return (
    <div id='threatAssessmentInterview'>
          <div>
            { assessment ? 
            <div>
              <Link to={`/assessment/${assessment.threatAssessmentId}`}>
              <h1>{assessment.assessedFirstName} {assessment.assessedLastName}</h1>
              </Link>
              
              <div>
                  {assessment.comments}
              </div>
            </div>
            : null }

            { !loading ?
            <div>                
                <form onSubmit={handleSave}>
                  { !interview.config.isQuestionaire ?
                  <section>
                          <h2>{ interview.config.interviewText} <HelpToolTip className="help" title={interview.config.helpText}></HelpToolTip></h2>
                    <Paper className="paper">
                      { interview.config?.header ?
                      <div className="flex-row">
                        <div className="flex-col header">
                          <span dangerouslySetInnerHTML={{ __html: interview.config.header }}></span>
                        </div>
                      </div>
                      : null }

                      <div className="flex-row">
                          <div className="flex-col">
                        { interview.config.intervieweeName === 'studentOfConcern' ?
                          <span><label>Person Interviewed:</label> {interview.intervieweeFirstName} {interview.intervieweeLastName}</span>
                        :
                          <div className="flex-row">
                            <div className="flex-col">
                              <TextField  value={interview.intervieweeFirstName || ''} name='intervieweeFirstName' maxLength="100" type="text" label="Person Interviewed First Name" required onChange={(e) => { interview.intervieweeFirstName = e.target.value; setInterview(interview)}}/> 
                            </div>
                            <div className="flex-col">
                              <TextField  value={interview.intervieweeLastName || ''} name='intervieweeLastName' maxLength="100" type="text" label="Person Interviewed Last Name" required onChange={(e) => { interview.intervieweeLastName = e.target.value; setInterview(interview)}}/> 
                            </div>
                          </div>
                        }
                        </div>
                      </div>

                    { interview.config.gatherExtraInfo ?
                      <div>
                        <div className="flex-row">
                          <div className="flex-col">
                            <TextField select label="Identification"  required value={interview.gender || ''} name="gender" onChange={(e) => { interview.gender = e.target.value; setInterview(interview)}} >
                              <MenuItem value="Male">Male</MenuItem>
                              <MenuItem value="Female">Female</MenuItem>
                              <MenuItem value="Neutral">Neutral</MenuItem>
                            </TextField>
                          </div>
                          <div className="flex-col">
                            <TextField  id="create-grade" value={interview.grade || ''} name='grade' type="number" label="Grade (if student)" onChange={(e) => { interview.grade = e.target.value; setInterview(interview)}}/> 
                          </div>
                          <div className="flex-col">
                            <TextField  id="create-age" value={interview.age || ''} name='age' type="number" label="Age" required onChange={(e) => { interview.age = e.target.value; setInterview(interview)}}/> 
                          </div>
                        </div>

                        <div className="flex-row">
                          <div className="flex-col">
                            <TextField  maxLength="30" value={interview.intervieweeID || ''} name='intervieweeID' type="text" label="ID" onChange={(e) => { interview.intervieweeID = e.target.value; setInterview(interview)}}/> 
                          </div>
                          <div className="flex-col">
                            <TextField  maxLength="100" value={interview.school || ''}  name='school' type="text" label="School" onChange={(e) => { interview.school = e.target.value; setInterview(interview)}}/> 
                          </div>
                          <div className="flex-col">
                            <TextField  maxLength="100"value={interview.building || ''}  name='building' type="text" label="Building" onChange={(e) => { interview.building = e.target.value; setInterview(interview)}}/> 
                          </div>
                        </div>

                        <div className="flex-row">
                          <div className="flex-col">
                            <TextField  required maxLength="75" value={interview.intervieweeHomeAddress || ''}  name='intervieweeHomeAddress' type="text" label="Home Address" onChange={(e) => { interview.intervieweeHomeAddress = e.target.value; setInterview(interview)}}/> 
                          </div>
                          <div className="flex-col">
                            <TextField  required maxLength="20" value={interview.intervieweePhone || ''}  name='intervieweePhone' type="text" label="Phone" onChange={(e) => { interview.intervieweePhone = e.target.value; setInterview(interview)}}/> 
                          </div>
                        </div>

                        <div className="flex-row">
                          <div className="flex-col">
                            <TextField select  required value={interview.intervieweeType || ''}  name="intervieweeType" label="Affiliation" onChange={(e) => { interview.intervieweeType = e.target.value; setInterview(interview)}} >
                                <MenuItem value="Administrator">Administrator</MenuItem>
                                <MenuItem value="Teacher">Teacher</MenuItem>
                                <MenuItem value="Staff">Staff</MenuItem>
                                <MenuItem value="Student">Student</MenuItem>
                                <MenuItem value="Parent/Guardian">Parent/Guardian</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </div>
                          <div className="flex-col">
                            <div className="flex-content">
                              <FormControl component="fieldset">
                                <RadioGroup row>
                                  <FormControlLabel label="Current" value="1" control={<Radio required />} checked={interview.isCurrentIntervieweeType == 1}  name="isCurrentIntervieweeType" type="radio" required onChange={(e) => { interview.isCurrentIntervieweeType = e.target.value; setInterview(interview)}}/>
                                  <FormControlLabel label="Former" value="0" control={<Radio required />} checked={interview.isCurrentIntervieweeType == 0} name="isCurrentIntervieweeType" type="radio" required onChange={(e) => { interview.isCurrentIntervieweeType = e.target.value; setInterview(interview)}} />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div className="flex-row">
                          <div className="flex-col">
                            <TextField  required maxLength="50" value={interview.emergencyContactName ?? ''} name='emergencyContactName' type="text" label="Emergency Contact Name" onChange={(e) => { interview.emergencyContactName = e.target.value; setInterview(interview)}}/> 
                          </div>
                          <div className="flex-col">
                            <TextField  required maxLength="10" value={interview.emergencyContactRelationship ?? ''} name='emergencyContactRelationship' type="text" label="Emergency Contact Relationship" onChange={(e) => { interview.emergencyContactRelationship = e.target.value; setInterview(interview)}}/> 
                          </div>
                        </div>
                      </div>
                    : null }
                    
                    <div className="flex-row">
                      <div className="flex-col">
                        <TextField select required value={interview.methodOfInterview || ''} label="Method of Inquiry" onChange={(e) => { interview.methodOfInterview = e.target.value; setInterview(interview)}} >
                          <MenuItem value="Telephone">Telephone</MenuItem>
                          <MenuItem value="Face-to-Face">Face-to-Face</MenuItem>
                          <MenuItem value="Email">Email</MenuItem>
                          <MenuItem value="Text">Text</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                      </div>

                      { interview.methodOfInterview === 'Face-to-Face' || interview.methodOfInterview === 'Other' ?
                        <div className="flex-col">
                          <TextField  maxLength="45" value={interview.methodOfInterviewLocation || ''}  name='methodOfInterviewLocation' type="text" label="Method of Inquiry Location" onChange={(e) => { interview.methodOfInterviewLocation = e.target.value; setInterview(interview)}}/> 
                        </div>
                      : null}
                    </div>

                    <AdditionalInterviewer assessmentModel={assessmentModel} assessment={assessment} interview={interview} onChange={(additionalInterviewers) => { if (!interview.data) interview.data = {}; interview.data.additionalInterviewers = additionalInterviewers; setInterview(interview)}} />

                  </Paper>
                </section>
                : null }

                { interview.config.isQuestionaire ?
                  <h2>Interview Questions</h2>
                : null }
                { interview.config.subTitle ?
                  <div>{interview.config.subTitle}</div>
                : null }

                { interview.sections.map( (section, index) => {
                  return (
                    <div key={index}>
                      { section.section ?
                        <div>
                          <h3>{section.section}</h3>
                          <div className="interviewQuestion-subText">
                            {section.sectionSubText}
                          </div>
                        </div>
                      : null }

                      <ol className="interviewQuestions">
                        { section.questions.map( (interviewQuestion, index) => {
                          let interviewQuestionClass = interviewQuestion.hide ? 'noshow' : ''
                          return (
                            <Paper key={index} className="paper">
                              <li className={`rows interviewQuestion ${interviewQuestionClass}`}  >
                                  <div>
                                    {!interviewQuestion.answer.length && interviewQuestion.hide ? <span>Skip: </span> : null }
                                    {interviewQuestion.text}
                                  </div>
                                  <div className="interviewQuestion-subText">
                                    {interviewQuestion.subText}
                                  </div>
                                  { interviewQuestion.previouslyAnswered ? <div className="previouslyAnswered">(Previously answered)</div> : null}
                                  <div>
                                    <span key={index} className="interviewQuestion-answer">
                                      { interviewQuestion.answerType === 'radio' ?   
                                          <FormControl component="fieldset">
                                            <RadioGroup row>
                                              { interviewQuestion.answerChoices.map( (answerChoice, index) => {
                                                return (
                                                  <FormControlLabel key={index} label={answerChoice.text} control={<Radio required />} checked={interviewQuestion.answer ? !!interviewQuestion.answer.find( a => a.threatAssessmentInterviewQuestionAnswerChoiceId === answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId ) : false } value={answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId} type="radio" disabled={!interviewQuestion.answer.length && interviewQuestion.hide} required onChange={(e) => handleAnswerChange(e, interviewQuestion) }/>
                                                )
                                              })}
                                            </RadioGroup>
                                          </FormControl>
                                      : null }
                                      { interviewQuestion.answerType === 'checkBox' ?
                                          <FormGroup row>
                                            { interviewQuestion.answerChoices.map( (answerChoice, index) => {
                                              return (
                                                <FormControlLabel key={index} label={answerChoice.text} control={<Checkbox checked={interviewQuestion.answer ? !!interviewQuestion.answer.find( a => a.threatAssessmentInterviewQuestionAnswerChoiceId === answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId ) : false } value={answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId} type="checkbox" disabled={!interviewQuestion.answer.length && interviewQuestion.hide} required={!interviewQuestion.answer || !interviewQuestion.answer.length} onChange={(e) => handleAnswerChange(e, interviewQuestion) } />} />
                                              )
                                            })}
                                          </FormGroup>
                                      : null }
                                    </span>
                                  </div>
                                  { interviewQuestion.config?.triggerAdditionalAssessment?.who === 'studentToAssess' && interviewQuestion.answer && interviewQuestion.answer.some( a => a.isYes) ?
                                    <div className="flex-row">
                                      <div className="flex-col">
                                        <TextField  name='studentToAssessFirst' maxLength="25" type="text" value={interviewQuestion.studentToAssessFirst} label="First Name of Subject to Assess" onChange={(e) => handleTriggerAdditionalAssessmentNameChange(e, interviewQuestion)}/> 
                                      </div>
                                      <div className="flex-col">
                                      <TextField  name='studentToAssessLast' maxLength="25" type="text" value={interviewQuestion.studentToAssessLast} label="Last Name of Subject to Assess" onChange={(e) => handleTriggerAdditionalAssessmentNameChange(e, interviewQuestion)}/> 
                                      </div>
                                    </div>
                                  : null }
                                  { !interviewQuestion.hide ?
                                    <div className="flex-row">
                                     <div className="flex-col">
                                      <TextField multiline maxRows={5}  inputProps={{ maxLength: 1200}}  maxLength="1200" name='comment' label="Comments" value={interviewQuestion.comments.comment ?? ''} disabled={interviewQuestion.hide} onChange={(e) => { interviewQuestion.comments = interviewQuestion.comments ?? {}; interviewQuestion.comments.comment = e.target.value; setInterview(interview)} }/> 
                                    </div>
                                    </div>
                                  : null }
                            </li>
                          </Paper>
                        )
                      })}
                      </ol>

                      { section.sectionConfig?.footer ?
                        <div>
                          { section.sectionConfig.footer }
                        </div>
                      : null }

                    </div>
                  )
                })}
                
                { interview.config.footerText ?
                  <div className="interviewQuestion-footerText">{interview.config.footerText}</div>
                : null }

                <div className="save-container no-print">
                  <Button variant="outlined" type="submit">
                    <Icon className="fa fa-save" />Save
                  </Button>
                </div>
              </form>
            </div>
          : null }
        </div>
      </div>
          
  )


}
