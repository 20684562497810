import React, { useEffect, useState,  useContext } from 'react';
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { Button, Icon, Paper } from '@mui/material';
import moment from 'moment'
import * as Sentry from '@sentry/browser';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import threatAssessmentService from '../../services/threatAssessment';
import institutionService from '../../services/institution';

import AppContext from '../../context/app'

import ActionButtons from '../../components/ActionButtons'
import DotMenu from '../../components/DotMenu';
import DotMenuItem from '../../components/DotMenuItem';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Banner from '../../components/Banner';

import ShareDialog from '../../components/ShareDialog';
import NotifyDialog from '../../components/NotifyTemplateDialog';


const DAYS_TO_WARN_TO_RESOLVE = 5


export default function TabRouter(props) {
  const location = useLocation()
  const history = useHistory()
  const { threatAssessmentId } = useParams()
  const context = useContext(AppContext)
  const [tabIndex, setTabIndex] = React.useState(0);
  const [error, setError] = useState()
  const [assessment, setAssessment] = useState(null)
  const [assessmentModel, setAssessmentModel] = useState(null)
  const [banner, setBanner] = useState(null)
  const [tabs, setTabs] = useState([])
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openNotifyDialog, setOpenNotifyDialog] = useState(false)
  const [showSupplemental, setShowSupplemental] = useState(false)

  const handleDownloadCase = (e) => {
    new threatAssessmentService().download(+threatAssessmentId)
  }

  const handleReSendRequestReview = async (e) => {
    try {
        await new threatAssessmentService().resendRequestReview(+threatAssessmentId)
        context.toastSuccess('Request for Review Sent')
    }
    catch(err){
      console.log(err)
      context.toastError('Request for review could not be sent')
      Sentry.captureException(err);
    }
   
  }


const handleCancel = async (event) => {
    event.preventDefault();

    try {
        let threatAssessment = { threatAssessmentId, isOpen: 0 };

        await new threatAssessmentService().update(threatAssessment)
        history.push({pathname: `/list` });
        context.toastSuccess('Assessment cancelled')
    }
    catch(err){
      console.log(err)
      context.toastError('Assessment could not be cancelled')
      Sentry.captureException(err);
    }
}

const handleOpen = async (event) => {
    event.preventDefault();

    try {
        let threatAssessment = { threatAssessmentId, isOpen: 1 };

        await new threatAssessmentService().update(threatAssessment)
        threatAssessment = assessment
        threatAssessment.isOpen = 1
        setAssessment(threatAssessment)
        context.toastSuccess('Assessment opened')
    }
    catch(err){
      console.log(err)
      context.toastError('Assessment could not be opened')
      Sentry.captureException(err);
    }

}

//delete the threat Assessment
const handleDelete = async (event) => {
    event.preventDefault();
    try {
        let threatAssessment = { threatAssessmentId }

        await new threatAssessmentService().delete(threatAssessment)
        history.push({pathname: `/list` });
        context.toastSuccess('Assessment deleted')
    }
    catch(err){
      console.log(err)
      context.toastError('Assessment could not be deleted')
      Sentry.captureException(err);
    }

}

const processInterview = (assessment, assessmentModel) => {
    //you are the user these are the ones that are requested of you (do not show already completed and needed only 1)
    let usersRequestedInterviews = assessmentModel.interviewTypes.filter( (it) => { return (it.config.autoAssignTo === 'all' || assessment.assigned.find( a => a.role === it.role )) && !(it.config.oneNeeded && assessment.interviewers.find( i => i.role === it.role) )})
    assessment.supplementalRequested = usersRequestedInterviews.filter( (assigned) => { return assigned.config.isSupplemental } )
    assessment.requiredRequested = usersRequestedInterviews.filter( (assigned) => { return !assigned.config.isSupplemental } )        
    return assessment
  }


const processBanner = (assessment, assessmentModel) => {
    //if every assignment has at least 1 interview complete, or a day has passed remind to resolve for threat assessments
    if (assessment.isOpen && assessment.typeName === 'Threat Assessment' && assessment.shouldResolve && !assessment.resolutionClassification && !assessment.resolutionNotes)
        setBanner({ title: 'Warning', message:"As required by state guidelines, please create an action plan for this case", severity:"info", todo: { link: `/assessment/${threatAssessmentId}`, text: 'Action Plan' }})

    //if high risk, supplemental is required if not already complete
    if (assessment.isOpen && assessment.typeName === 'Threat Assessment' && assessment.threatLevelClassification === 'Extremely High Risk') {
        setShowSupplemental(true)
        let supplementalInterviewTypes = assessmentModel.interviewTypes.filter( (assigned) => { return assigned.config.isSupplemental } )
        if (supplementalInterviewTypes.find( a => !assessment.interviewers.find( i => i.role === a.role)))
            setBanner({ title: 'Warning', message:`Due to the high risk nature of this threat assessment, State law requires that you complete supplemental threat assessment interviews before creating an action plan for this case<br>
            <br>
            A mental health assessment is usually conducted in cases involving a very serious substantive threat. The purpose of the mental health assessment is to maintain the safety and well-being of the student and others. Therefore, the assessment has two objectives:<br>
            <ol>
            <li>Treatment and referral needs. Assess the student’s present mental state and determine whether there are urgent mental health needs that require attention, such as risk of suicide, psychosis, or rage. Beyond these immediate needs, consider whether there are other treatment, referral, or support needs.</li>
            <li>Threat reduction. Gather information on the student’s motives and intentions in making the threat in order to understand why the threat was made and identify relevant strategies or interventions that have the potential to reduce the risk of violence. </li>
            </ol><br>
            <b>Please click Perform Interview to perform supplemental interviews.</b>`, 
            severity:"info"})
        }
  }

  const processTabs = (assessment, assessmentModel) => {
    let tabs = assessmentModel.config.tabs.filter( t => { return  !t.showRule || eval(t.showRule)(assessment, context.user) })
    tabs.forEach( t => t.to = eval(t.to)(assessment, context.user))
    setTabs(tabs)
  }

  useEffect( () => {
    //run through tab rules
    let tabIndex = 0
    let pathTabIndex = tabs.findIndex( (t) => location.pathname === t.to )
    tabIndex = pathTabIndex >= 0 ? pathTabIndex : tabIndex
    setTabIndex(tabIndex);
  },[location, tabs])

  useEffect( () => {
    if (!props.disableRouting && tabs && assessment){
        //run through tab rules
        let gotoTab = tabs.find( t => t.gotoRule && eval(t.gotoRule)(assessment))
        if (gotoTab)
            history.push({pathname: gotoTab.to })
        else
            history.push({pathname: tabs[0].to })
    }
  },[assessment])


  useEffect( async () => {
    window.scroll(0,0)
    try {        
        let loadedAssessment = await new threatAssessmentService().get(threatAssessmentId)
        if (loadedAssessment.error){
            setError(loadedAssessment.error)
            return
        }
        //load additional data
        let [institution, assessmentModel] = await Promise.all([
            await new institutionService().get(loadedAssessment.institutionId),
            await new threatAssessmentService().getAssessmentModelByAssessmentId(threatAssessmentId)
        ]) 

        let assignableInterviewRoles = assessmentModel.interviewTypes.filter( it => !it.config.notAssignable && !['all','creator'].includes(it.config.autoAssignTo)).map( ai => ai.role)
        loadedAssessment.interviewsNeedAssignment = assignableInterviewRoles.length && !!assignableInterviewRoles.find( r => !loadedAssessment.assigned.find( a => a.role === r ) )
        setAssessmentModel(assessmentModel)
        loadedAssessment = processInterview(loadedAssessment, assessmentModel)        
        //additional access
        loadedAssessment.ownerAccess = context.user && (loadedAssessment.userId === context.user.userId || context.user.viewAllThreats )
        loadedAssessment.shouldResolve = loadedAssessment.ownerAccess && loadedAssessment.isOpen && loadedAssessment.threatLevelClassification && ((moment(loadedAssessment.createDate) <= moment().subtract(DAYS_TO_WARN_TO_RESOLVE, 'day') && loadedAssessment.interviewers.length) || (loadedAssessment.assigned.length && loadedAssessment.requiredRequested.every( a => loadedAssessment.interviewers.filter( i => i.role === a.role).length >= 1 )))
       
        if (loadedAssessment.threatAssessmentModelId !== 7)
            processBanner(loadedAssessment, assessmentModel)
        processTabs(loadedAssessment, assessmentModel) 

        setAssessment(loadedAssessment)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[threatAssessmentId, props.load])

  if (error)
    return(<Paper className="paper">Case has been deleted or you do not have access.</Paper>)


  return (
    <div id="assessmentTabRouter" className='no-print'>
    { assessment && assessmentModel ?
      <section>
        { banner ? 
            <Banner title={banner.title} message={banner.message} severity={banner.severity} todo={banner.todo}></Banner>
        : null }
        <ShareDialog assessment={assessment} open={openShareDialog} closeDialog={(e) => setOpenShareDialog(false)}></ShareDialog>
        <NotifyDialog assessment={assessment} open={openNotifyDialog} closeDialog={(e) => setOpenNotifyDialog(false)}></NotifyDialog>

        <div className='flex-row assessment-header'>
            <div className='flex-col'>
                <h1 className='print'>{assessment.assessedFirstName} {assessment.assessedLastName}</h1>
            </div>
        
            <div className='flex-col'>
                <div className="flex-row actions">
                    { assessment.ownerAccess && assessment.interviewsNeedAssignment ? 
                        <div className="flex-col">
                            <div>
                                <Link to={`/edit/${threatAssessmentId}`}>
                                    <Button variant="outlined"><Icon className="fa fa-users" />Assign Interviews</Button>
                                </Link>
                            </div>
                        </div>
                    : null }


                    { assessment.ownerAccess && assessment.isOpen && assessment.config.resources?.find( r => r.isNotify ) ? 
                        <div className="flex-col">
                            <Button onClick={(e) => setOpenNotifyDialog(true)} variant="outlined"><Icon className="fa fa-envelope" />Notify</Button>
                        </div>
                    : null }

                    { assessment.isOpen && (assessment.requiredRequested.length || (showSupplemental && assessment.supplementalRequested.length)) ?
                        <div className="flex-col" >
                            <ActionButtons icon="comment" title="Interview Forms">
                                <MenuList className="perform-interview-list" >
                                { assessment.requiredRequested.map( (interviewer, index) => {
                                        return (
                                            <MenuItem  key={index}>
                                                <Link className='interview-link' to={`/interview/${assessment.threatAssessmentId}/${interviewer.role}`}>
                                                    <div className="perform-interview">
                                                        <Icon className="fa fa-comment"/>
                                                        <span className="perform-interview-text">{ `${interviewer.config.interviewText}` }</span>
                                                    </div>
                                                </Link>
                                            </MenuItem>
                                        );
                                    })
                                }
                                { showSupplemental && assessment.isOpen ?
                                <div>
                                    {  assessment.supplementalRequested.map( (interviewer, index) => {
                                            return (
                                                <MenuItem key={index}>
                                                    <Link className='interview-link' to={`/interview/${assessment.threatAssessmentId}/${interviewer.role}`}>
                                                        <div className="perform-interview">
                                                            <Icon className="fa fa-comment"/>
                                                            <span className="perform-interview-text">{ `${interviewer.config.interviewText}` }</span>
                                                        </div>
                                                    </Link>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </div>
                            : null }
                                </MenuList>
                            </ActionButtons>
                        </div>
                    : null }

                    { !assessment.isOpen ?
                         <div className="flex-col">
                            <Button variant="outlined" onClick={handleOpen}><Icon className="fa fa-box-open" />Re-Open</Button>
                        </div>
                    : 
                        <div className="flex-col">
                            <Button variant="outlined" onClick={handleCancel}><Icon className="fa fa-archive" />Archive</Button>
                        </div>
}

                    <div id="menu-col" className="flex-col" >
                        <div className="">
                            <DotMenu>
                                { assessment.ownerAccess && assessment.isOpen && assessment.supplementalRequested.length && !showSupplemental ?
                                    <DotMenuItem onClick={(e) => setShowSupplemental(true)} fontAwesomeIcon="fa-search-plus" title="Enable Supplemental Interviews" ></DotMenuItem>
                                : null }
                                { assessment.ownerAccess ?
                                    <Link to={`/edit/${assessment.threatAssessmentId}`}><DotMenuItem fontAwesomeIcon="fa-edit" title="Edit" ></DotMenuItem></Link>
                                : null }
                                { assessment.ownerAccess ?
                                    <DotMenuItem onClick={handleDownloadCase} fontAwesomeIcon="fa-download" title="Download Case" ></DotMenuItem> 
                                : null }
                                { assessment.ownerAccess && assessment.resolutionClassification && assessment.threatAssessmentModelId === 7 && assessment.reviewRequests.length ? 
                                    <DotMenuItem onClick={handleReSendRequestReview} fontAwesomeIcon="fa-envelope" title="ReSend Request Review" ></DotMenuItem> 
                                : null}
                                { assessment.ownerAccess ?
                                    <DotMenuItem onClick={(e) => setOpenShareDialog(true)} fontAwesomeIcon="fa-envelope" title="Share Case" ></DotMenuItem> 
                                : null }
                                { assessment.ownerAccess ?
                                    <DotMenuItem onClick={handleDelete} fontAwesomeIcon="fa-trash" title="Delete" ></DotMenuItem>
                                : null }
                            </DotMenu>
                        </div>
                    </div>
                </div>
        </div>
       </div>

        <div className="tab no-print-hide">
            { tabs.length ?
                <Box sx={{ width: '100%' }}>
                    <Tabs value={tabIndex} onChange={(e, newTabIndex) => setTabIndex(newTabIndex)}>
                        { tabs.map( (tab, index) => {
                            return (<Tab key={index} label={tab.label} component={Link} to={tab.to} /> )
                        })
                        }
                    </Tabs>
                </Box>
            : null }
        </div>
      </section>
      : null }
    </div>
  );
}