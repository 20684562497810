import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, Checkbox, TextField, MenuItem, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers';

import HelpToolTip from '../../../components/HelpToolTip';
import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';
import DeterminationHelpText from '../../../config/HPTM/DeterminationHelpText.json'

const formId = 2

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { threatAssessmentId: props.threatAssessmentId, formId, data: {} }
    );

    useEffect(async () => {
        if (props.editForm) {
            setForm(JSON.parse(JSON.stringify(props.editForm)))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (loading) return
        form.classification = form.data.updatedClassification || form.data.classification
        setForm(form)
    }, [form.data.classification, form.data.updatedClassification])

    useEffect(() => {
        if (loading) return
        //changed and set to specific values
        form.sendForReviewType = (!props.editForm || (props.editForm.data.classification !== form.data.classification)) ? 'final' : null
        setForm(form)
    }, [form.classification])


    const handleSave = async (event) => {
        event.preventDefault();
        try {
            if (props.editForm)
                await new formService().update(form)
            else
                await new formService().save(form)
            props.history.push({ pathname: `/assessment/${form.threatAssessmentId}` });
            context.toastSuccess('Form Saved')
        }
        catch (err) {
            console.log(err)
            context.toastError('Form could not be saved')
            Sentry.captureException(err);
        }
    }


    return (
        <div id="assessmentForm">
            <form onSubmit={handleSave}>
                <Paper className="paper">
                    <div className="flex-row">
                        <div className="flex-col">
                            <div>
                                Determination:
                            </div>
                            <FormControl component="fieldset">
                                <RadioGroup row>
                                    <FormControlLabel label="Unfounded" control={<Radio required />} checked={form.data.classification === 'Unfounded'} type="radio" required onChange={() => { form.data.classification = 'Unfounded'; setForm(form) }} />
                                    <FormControlLabel label="Low Level of Concern" control={<Radio required />} checked={form.data.classification === 'Low Level of Concern'} type="radio" required onChange={() => { form.data.classification = 'Low Level of Concern'; setForm(form) }} />
                                    <FormControlLabel label="Medium Level of Concern" control={<Radio required />} checked={form.data.classification === 'Medium Level of Concern'} type="radio" required onChange={() => { form.data.classification = 'Medium Level of Concern'; setForm(form) }} />
                                    <FormControlLabel label="High Level of Concern" control={<Radio required />} checked={form.data.classification === 'High Level of Concern'} type="radio" required onChange={() => { form.data.classification = 'High Level of Concern'; setForm(form) }} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    {DeterminationHelpText[form.data.classification] ?
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: DeterminationHelpText[form.data.classification] }}></span>
                        </div>
                        : null}

                    <div className="flex-row">
                        <div className="flex-col">
                            <TextField label='Provide Brief Factual Basis for Determination' maxLength="500" type="text" value={form.data?.classificationNotes || ''} onChange={(e) => { form.data.classificationNotes = e.target.value; setForm(form) }} />
                        </div>
                    </div>
                </Paper>

                {form.data.classification && form.data.classification !== 'Unfounded' ?
                    <section>
                        <Paper className="paper">
                            {form.data.classification === 'Low Level of Concern' ?
                                <section>
                                    <div className="flex-row">
                                        <div className="flex-col check-box-with-help">
                                            <FormControlLabel control={<Checkbox checked={!!form.data?.wasSSMPImplemented} onChange={(e) => { form.data.wasSSMPImplemented = !!e.target.checked; setForm(form) }} />} label="Was SSMP Implemented" />
                                            <HelpToolTip title={<div>Not required however, if implemented, the minimum period for a Low level of concern SSMP is 90 days</div> }></HelpToolTip>
                                        </div>
                                    </div>

                                    {!form.data?.wasSSMPImplemented ?
                                        <div className="flex-row">
                                            <div className="flex-col">
                                                <TextField label='Why Not' maxLength="200" type="text" value={form.data?.ssmpNotImplementedReason || ''} onChange={(e) => { form.data.ssmpNotImplementedReason = e.target.value; setForm(form) }} />
                                            </div>
                                        </div>
                                        : null}

                                    {form.data?.wasSSMPImplemented ?
                                        <section>
                                        <div className="flex-row">
                                            <div className="flex-col">
                                                <TextField label='Duration of SSMP' maxLength="100" type="text" value={form.data?.ssmpDuration || ''} onChange={(e) => { form.data.ssmpDuration = e.target.value; setForm(form) }} />
                                            </div>
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-col">
                                                <FormControlLabel control={<Checkbox checked={!!form.data?.isSsmpInterim} onChange={(e) => { form.data.isSsmpInterim = !!e.target.checked; setForm(form) }} />} label="Is this an interim SSMP" />
                                            </div>
                                        </div>
                                        </section>
                                        : null}
                                </section>
                                : null}

                            {form.data.classification === 'Medium Level of Concern' || form.data.classification === 'High Level of Concern' ?
                                <section>
                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date SSMP Implemented" value={form.data?.ssmpImplementedDate || null} onChange={(e) => { if (!e) { return }; form.data.ssmpImplementedDate = e.format(); form.data.wasSSMPImplemented = true; setForm(form) }} />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <TextField label='Duration of SSMP' maxLength="100" type="text" value={form.data?.ssmpDuration || ''} onChange={(e) => { form.data.ssmpDuration = e.target.value; setForm(form) }} />
                                        </div>
                                        <div className="flex-col help-section">
                                            <HelpToolTip title={form.data.classification === 'Medium Level of Concern' ? <div>For medium threats, the SBTMT must monitor the SSMP for a minimum period of 180 days or 6 monthly threat team meetings</div> : <div>For high threats, the SBTMT must monitor the SSMP for a minimum period of one year or 12 monthly threat team meetings</div> }></HelpToolTip>                                        
                                        </div>
                                    </div>

                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <FormControlLabel control={<Checkbox checked={!!form.data?.isSsmpInterim} onChange={(e) => { form.data.isSsmpInterim = !!e.target.checked; setForm(form) }} />} label="Is this an interim SSMP" />
                                        </div>
                                    </div>
                                </section>
                                : null}

                            {form.data?.wasSSMPImplemented ?
                                <div className="flex-row">
                                    <div className="flex-col">
                                        <TextField label='State the Components and Requirements in the SSMP' minRows={4} required multiline  inputProps={{ maxLength: 2000}}  type="text" value={form.data?.ssmpRequirements || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.ssmpRequirements = e.target.value; setForm(form) }} />
                                    </div>
                                </div>
                                : null}

                            {form.data.classification === 'Medium Level of Concern' ?
                                <section>
                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <FormControlLabel control={<Checkbox checked={!!form.data?.referredToDTMT} onChange={(e) => { form.data.referredToDTMT = !!e.target.checked; setForm(form) }} />} label="Medium Level Reviewed by District Threat Management Coordinator, Referred to District Threat Management Team" />
                                        </div>
                                    </div>
                                </section>
                                : null}

                            {form.data.classification === 'High Level of Concern' ?
                                <section>
                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date High Level Determination Referred to District Threat Management Team" value={form.data?.referredToDTMTDate || null} onChange={(e) => { if (!e) { return }; form.data.referredToDTMTDate = e.format(); setForm(form) }} />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </section>
                                : null}

                            {form.data.classification === 'High Level of Concern' || form.data.referredToDTMT ?
                                <section>
                                    (District Threat Management Team must meet within two school days of receiving the referral.)
                                    <div className="flex-row section">
                                        <div className="flex-col">
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date District Threat Management Team Reviewed the Case" value={form.data?.reviewedByDTMTDate || null} onChange={(e) => { if (!e) { return }; form.data.reviewedByDTMTDate = e.format(); setForm(form) }} />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <FormControl component="fieldset">
                                                <RadioGroup row>
                                                    <FormControlLabel label="District Threat Management Team Referred Back to SBTMT" control={<Radio required />} checked={form.data.DTMTAction === 'Referred Back to SBTMT'} type="radio" required onChange={() => { form.data.DTMTAction = 'Referred Back to SBTMT'; setForm(form) }} />
                                                    <div className="radio-help"><HelpToolTip title={<div>Include dates referred back to the SBTMT from the district team and any comments the latter provided</div> }></HelpToolTip></div>

                                                    <FormControlLabel label="District Threat Management Team Providing Support to SBTMT" control={<Radio required />} checked={form.data.DTMTAction === 'Providing Support to SBTMT'} type="radio" required onChange={() => { form.data.DTMTAction = 'Providing Support to SBTMT'; setForm(form) }} />
                                                    <div className="radio-help"><HelpToolTip title={<div>Include any district team comments and dates for follow-up review</div> }></HelpToolTip></div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {form.data.DTMTAction === 'Referred Back to SBTMT' ?
                                        <div className="flex-row">
                                            <div className="flex-col">
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField required {...props} />} label="Date Referred Back to the SBTMT" value={form.data?.DTMTReferredBackDate || null} onChange={(e) => { if (!e) { return }; form.data.DTMTReferredBackDate = e.format(); setForm(form) }} />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        : null}

                                    <div className="flex-row">
                                        <div className="flex-col">
                                            <TextField label='District Threat Management Team Comments' required multiline type="text"  inputProps={{ maxLength: 500}}  value={form.data?.dtmtComments || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.dtmtComments = e.target.value; setForm(form) }} />
                                        </div>
                                    </div>
                                </section>
                                : null}

                            {form.data.classification === 'High Level of Concern' ?

                                <div className="flex-row">
                                    <div className="flex-col">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker disableFuture={true} fullWidth renderInput={(props) => <TextField {...props} />} label="Subsequent SSMP Review Date" value={form.data?.ssmpReviewDate || null} onChange={(e) => { if (!e) { return }; form.data.ssmpReviewDate = e.format(); setForm(form) }} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                : null}
                        </Paper>
                    </section>
                    : null}

                {form.data.classification === 'High Level of Concern' ?
                    <Paper className='paper'>
                        <div className="flex-row">
                            <div className="flex-col">
                                <FormControlLabel control={<Checkbox checked={!!form.data?.isLevelOfConcernModified} onChange={(e) => { form.data.isLevelOfConcernModified = !!e.target.checked; setForm(form) }} />} label="Was the Level of Concern Modified" />
                            </div>
                        </div>

                        {form.data?.isLevelOfConcernModified ?
                            <div className="flex-row">
                                <div className="flex-col">
                                    <FormControl component="fieldset">
                                        <RadioGroup row>
                                            <FormControlLabel label="Unfounded" control={<Radio required />} checked={form.data.updatedClassification === 'Unfounded'} type="radio" required onChange={() => { form.data.updatedClassification = 'Unfounded'; setForm(form) }} />
                                            <FormControlLabel label="Low Level of Concern" control={<Radio required />} checked={form.data.updatedClassification === 'Low Level of Concern'} type="radio" required onChange={() => { form.data.updatedClassification = 'Low Level of Concern'; setForm(form) }} />
                                            <FormControlLabel label="Medium Level of Concern" control={<Radio required />} checked={form.data.updatedClassification === 'Medium Level of Concern'} type="radio" required onChange={() => { form.data.updatedClassification = 'Medium Level of Concern'; setForm(form) }} />
                                            <FormControlLabel label="High Level of Concern" control={<Radio required />} checked={form.data.updatedClassification === 'High Level of Concern'} type="radio" required onChange={() => { form.data.updatedClassification = 'High Level of Concern'; setForm(form) }} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            : null}

                        <div className="flex-row">
                            <div className="flex-col">
                                <FormControlLabel control={<Checkbox checked={!!form.data?.isSSMPModified} onChange={(e) => { form.data.isSSMPModified = !!e.target.checked; setForm(form) }} />} label="Was SSMP Modified" />
                            </div>
                        </div>

                        {form.data?.isSSMPModified ?
                            <div className="flex-row">
                                <div className="flex-col">
                                    <TextField label='State Modifications' minRows={4}  inputProps={{ maxLength: 500}}  required multiline type="text" value={form.data?.ssmpModifications || ''} onChange={(e) => { if (!form.data) form.data = {}; form.data.ssmpModifications = e.target.value; setForm(form) }} />
                                </div>
                            </div>
                            : null}
                    </Paper>
                    : null}


                {form.sendForReviewType ?
                    <div className="reviewMessage">
                        <i className="fa fa-envelope"></i>An email will be sent for review
                    </div>
                    : null}

                <div>
                    <Button variant="outlined" type="submit">
                        <Icon className="fa fa-save" />Save
                    </Button>
                </div>
            </form>
        </div>
    )
}